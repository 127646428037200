<div class="form-group relative" [formGroup]="form">

    <ng-container *ngIf="label">
        <div class="d-flex align-items-center">
            <span class="required-marker mr-1 mb-1">{{getRequiredMarker()}}</span>
            <label appEllipsify [enableTooltip]="enableTooltip" class="w-100">{{label | translate}}</label>
            <ng-content select="[additionalLabel]"></ng-content>
        </div>
    </ng-container>
    <!-- TEXT, NUMBER... -->
    <div
        *ngIf="type !== 'date' && type !== 'password' && type !== 'tel' && type !== 'icon-input' && type !== 'currency' && type !== 'color' && type !== 'textarea'">
        <input [type]="getType()"
               (change)="changeValue?.emit($event)"
               appNumericField
               [step]="step"
               name="{{controlName}}"
               class="form-control col {{customClass}}"
               formControlName="{{controlName}}"
               [min]="min"
               [max]="max"
               [maxlength]="maxlength"
               [mask]="mask"
               [suffix]="suffix"
               [placeholder]="hasPendentApproval ? '' : translatedPlaceholder"
               (blur)="removeEmptySpaces()"
               [disabled]="disabled"
               [numericType]="numericType"
               [dropSpecialCharacters]="dropSpecialCharacters"/>

        <app-input-error *ngIf="showErrorMessage"
                         field="{{controlName}}"
                         [messages]="errorMessages"
                         [formGroup]="form"
                         [showEmpty]="showEmpty">
        </app-input-error>
    </div>

    <div *ngIf="type === 'currency'">
        <input [type]="'text'"
               (change)="changeValue?.emit($event)"
               name="{{controlName}}"
               [min]="min"
               [max]="max"
               class="form-control col {{customClass}}"
               formControlName="{{controlName}}"
               [placeholder]="hasPendentApproval ? '' : translatedPlaceholder"
               (blur)="removeEmptySpaces()"
               [disabled]="disabled"
               currencyMask/>

        <app-input-error *ngIf="showErrorMessage"
                         field="{{controlName}}"
                         [messages]="errorMessages"
                         [formGroup]="form"
                         [showEmpty]="showEmpty">
        </app-input-error>
    </div>

    <div *ngIf="type === 'icon-input'">
        <div class="row">
            <input class="form-control col-11 {{customClass}}"
                   (change)="changeValue?.emit($event)"
                   [disabled]="true"
                   name="{{controlName}}"
                   formControlName="{{controlName}}"
                   [id]="this.idInput"
                   [mask]="mask"
                   [min]="min"
                   [max]="max"
                   [placeholder]="hasPendentApproval ? '' : translatedPlaceholder"
                   (keyup.escape)="disableInput()"
                   (keyup.tab)="disableInput()"
                   (keyup.enter)="disableInput()"
                   (blur)="disableInput()"
                   [disabled]="disabled"/>

            <div class="col-1 input-icon"
                 (click)="changeInputState()">
                <i class="material-icons icon">create</i>
            </div>

        </div>
        <app-input-error *ngIf="showErrorMessage"
                         field="{{controlName}}"
                         [messages]="errorMessages"
                         [formGroup]="form"
                         [showEmpty]="showEmpty">
        </app-input-error>
    </div>

    <!-- PHONE NUMBER -->
    <div *ngIf="type === 'tel'">
        <div class="input-group">
            <input [type]="getType()"
                   (change)="changeValue?.emit($event)"
                   [min]="min"
                   [max]="max"
                   name="{{controlName}}"
                   class="form-control col {{customClass}}"
                   formControlName="{{controlName}}"
                   placeholder="{{isViewMode ? '' : phoneMaskPlaceholder}}"
                   appPhoneMask
                   (blur)="removeEmptySpaces()"
                   [disabled]="disabled"/>
        </div>

        <app-input-error *ngIf="showErrorMessage"
                         field="{{controlName}}"
                         [messages]="errorMessages"
                         [formGroup]="form"
                         [showEmpty]="showEmpty">
        </app-input-error>
    </div>

    <!-- PASSWORD -->
    <div *ngIf="type === 'password'">
        <div class="d-inline-flex password-group">
            <input
                (change)="changeValue?.emit($event)"
                name="{{controlName}}"
                [type]="showPassword ? 'text' : 'password'"
                class="form-control col {{customClass}}"
                formControlName="{{controlName}}"
                [placeholder]="hasPendentApproval ? '' : translatedPlaceholder"
                (blur)="removeEmptySpaces()"
                [disabled]="disabled">

            <div class="input-group-append">
                 <span class="input-group-text">
                       <i class="fa" (click)="toggleShowPassword()"
                          [ngClass]="{'fa-eye-slash': !showPassword, 'fa-eye': showPassword}">
                       </i>
                 </span>
            </div>
        </div>
        <app-input-error *ngIf="showErrorMessage"
                         field="{{controlName}}"
                         [messages]="errorMessages"
                         [formGroup]="form"
                         [showEmpty]="showEmpty">
        </app-input-error>
    </div>


    <!-- DATE -->
    <div *ngIf="type === 'date'">
        <div class="input-group">
            <input type="text"
                   (dateChange)="changeValue?.emit($event)"
                   id="input-date"
                   #dateInput
                   class="form-control col"
                   [ngClass]="customClass"
                   [name]="controlName"
                   [matDatepicker]="pickerDate"
                   [formControlName]="controlName"
                   [max]="this.dateMax"
                   [min]="this.dateMin"
                   [value]="this.dateValue"
                   [disabled]="disabled"
                   appInputDateMask/>

            <div class="input-group-prepend" [ngStyle]="{'cursor: default': isDisabled(form.controls[controlName])}">
                <div class="input-group-text" (click)="pickerDate.open()" [ngClass]="{'has-error': hasError(form.controls[controlName]), 'disabled': isDisabled(form.controls[controlName])}">
                    <i class="material-icons tx-16 lh-0 op-6" [ngStyle]="{'color': hasError(form.controls[controlName]) ? 'red' : dateIconColor }">{{dateIcon}}</i>
                </div>
            </div>

            <mat-datepicker #pickerDate (closed)="onClosePickerDate()"></mat-datepicker>
        </div>
        <app-input-error
            *ngIf="showErrorMessage"
            field="{{controlName}}"
            [messages]="errorMessages"
            [formGroup]="form"
            [showEmpty]="showEmpty">
        </app-input-error>
    </div>

    <!-- COLOR -->
    <div *ngIf="type === 'color'">
        <div class="input-group">
            <input
            (change)="changeValue?.emit($event)"
            name="{{controlName}}"
            [type]="'color'"
            class="form-control col"
            formControlName="{{controlName}}"
            [placeholder]="hasPendentApproval ? '' : translatedPlaceholder"
            [disabled]="disabled">
        </div>
        <app-input-error    *ngIf="showErrorMessage"
                            field="{{controlName}}"
                            [messages]="errorMessages"
                            [formGroup]="form"
                            [showEmpty]="showEmpty">
        </app-input-error>
    </div>

    <div *ngIf="type === 'textarea'">
        <textarea name="{{controlName}}"
                  (change)="changeValue?.emit($event)"
                  class="form-control col {{customClass}}"
                  formControlName="{{controlName}}"
                  [placeholder]="hasPendentApproval ? '' : translatedPlaceholder"
                  (blur)="removeEmptySpaces()"
                  [disabled]="disabled">>
        </textarea>

        <app-input-error *ngIf="showErrorMessage"
                         field="{{controlName}}"
                         [messages]="errorMessages"
                         [formGroup]="form"
                         [showEmpty]="showEmpty">
        </app-input-error>
    </div>

    <div [class]="getInputComplementClass()" *ngIf="item">
        <app-historical-data-view *ngIf="hasHistoricalDataForThisProperty(item, customApprovalProperty || controlName)"
                                  [item]="item"
                                  [property]="customApprovalProperty || controlName"
                                  [form]="form"
                                  [controlName]="controlName">
        </app-historical-data-view>
        <app-approve-pending-changes *ngIf="hasPendentApprovalForThisProperty(item, customApprovalProperty || controlName)"
                                     [item]="item"
                                     [property]="customApprovalProperty || controlName"
                                     [form]="form"
                                     [controlName]="controlName"
                                     [permissionToApprove]="permissionToApprove">
        </app-approve-pending-changes>
    </div>

</div>

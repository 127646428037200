import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ErrorMessages } from '../input-error/input-error.component';
import { AbstractControl } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { InputMaskService } from '../../services/input-mask.service';
import * as uuid from 'uuid';
import { TranslateService } from '@ngx-translate/core';
import {UserService} from '../../services/user.service';

declare let $;

@Component({
    selector: 'app-input',
    templateUrl: './input.component.html',
    styleUrls: ['./input.component.scss'],
})
export class InputComponent implements OnInit {

    idInput = uuid.v4();
    showPassword = false;
    inputStates = false;

    now = new Date();
    @ViewChild('dateInput') dateInput: ElementRef;
    @ViewChild('pickerDate') pickerDate: MatDatepicker<Date>;

    // Optionals outputs
    
    /**
     * This is a secondary option for listening to the changes of the inputs if you don't want to use the form values changes 
     */
    @Output() changeValue = new EventEmitter<any>(); 

    // Required
    @Input() form!: any;
    @Input() controlName!: string;
    @Input() label!: string;
    @Input() enableEllipsify: boolean;
    @Input() enableTooltip: boolean;

    // Optionals
    @Input() step: number; // Step property for input numbers
    @Input() showErrorMessage = true;
    @Input() min;
    @Input() max;
    @Input() maxlength = 150;
    @Input() hasPendentApproval = false;
    @Input() customClass: string;
    @Input() placeholder: string;
    @Input() mask: string;
    @Input() suffix = '';
    @Input() type: string;
    @Input() numericType: 'float' | 'integer' = 'integer';
    @Input() errorMessages: ErrorMessages;
    @Input() errorIcon = true;
    @Input() disabled = false;
    @Input() isViewMode = false;
    @Input() dropSpecialCharacters = true;

    // Dates
    @Input() dateMax: Date;
    @Input() dateMin: Date;
    @Input() dateValue: Date;
    @Input() dateIconColor = 'gray';
    @Input() dateIcon = 'today';

    // Optional params to approve and see historical data
    @Input() item: any;
    @Input() permissionToApprove: string;
    @Input() customApprovalProperty: string;

    /** This attribute displays the gray mandatory attribute text below the input */
    @Input() showRequiredMarker = false;
    /** This attribute leaves a space below the entry, which will be the space for the error to appear */
    @Input() showEmpty = true;

    translatedPlaceholder = '';

    constructor(private inputMask: InputMaskService,
                private translateService: TranslateService,
                private userService: UserService) {
    }

    ngOnInit(): void {
        if (this.placeholder && !this.isViewMode) {
            this.translatedPlaceholder = this.translateService.instant(this.placeholder);
            this.translateService.onLangChange.subscribe(() => {
                this.translatedPlaceholder = this.translateService.instant(this.placeholder);
            });
        }
    }

    getType(): string {
        return this.type ? this.type : 'text';
    }

    removeEmptySpaces() {
        const value = this.form.get(this.controlName).value;
        if (value && this.type === 'text' || !this.type) {
            this.form.get(this.controlName).setValue(value.toString().trim());
        }
    }

    hasError(field: AbstractControl) {
        return field.invalid && (field.dirty || field.touched);
    }

    isDisabled(field: AbstractControl) {
        return field.disabled || this.disabled;
    }

    onClosePickerDate() {
        this.dateInput.nativeElement.blur();
    }

    toggleShowPassword() {
        this.showPassword = !this.showPassword;
    }

    get dateMaskPlaceholder() {
        return this.inputMask.dateMaskPlaceholder;
    }

    get phoneMaskPlaceholder() {
        return this.hasPendentApproval ? null : this.inputMask.phonePlaceholder;
    }

    changeInputState() {
        if (this.form.get(this.controlName).enabled) {
            this.inputStates = !this.inputStates;
            if (this.inputStates) {
                setTimeout(() => {
                    $(`#${this.idInput}`).prop('disabled', false);
                    $(`#${this.idInput}`).focus();
                }, 0);
            }
        }
    }

    disableInput() {
        this.inputStates = false;
        setTimeout(() => {
            $(`#${this.idInput}`).prop('disabled', true);
        }, 0);
    }

    getRequiredMarker() {
        const validator = this.form?.get(this.controlName)?.validator;
        return validator && validator({} as AbstractControl)?.required || this.showRequiredMarker ? '*' : '';
    }

    hasPendentApprovalForThisProperty(item: any, property: string) {
        if (!this.userService.loggedUser.finsteinUser && item && item.pendentApproval && item.pendentApproval.data) {
            return item.pendentApproval.data.find(data => data.property === property);
        }
    }

    hasHistoricalDataForThisProperty(item: any, property: string) {
        return item && item.historicalData &&
            item.historicalData.data &&
            item.historicalData.data.find(data => property?.includes(data.property));
    }

    getInputComplementClass() {
        return this.label ? 'input-complement-with-label' : 'input-complement-without-label';
    }

}
